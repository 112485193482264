import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageComprendreAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "comprendreAuroresBorealesEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "guideAuroresBorealesEN"
            "photographierAuroresBorealesEN"
            "galerieAuroresBorealesEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesLeslignesDeChampsMagnetiquesDunTrouCoronal"
            "auroresBorealesKp888Sur9"
            "auroreTaiga"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesLExperienceTerella"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUnovaleAuroral"
            "auroresBorealesMagnetosphereStructure"
            "auroresBorealesLaluminescence"
            "auroresBorealesUnbilletDe200KronerVerso"
            "auroresBorealesUnbilletDe200KronerRecto"
            "auroresBorealesLemodelePlanetaireDeBohr-Rutherford"
            "auroresBorealesLeventSolaireSouffleSurLaTerreDeformantLaMagnetosphere"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUneautreGravureDauroreDeFridtjofNansen"
            "auroresBorealesTachesSolaires"
            "auroresBorealesUntrouCoronal"
            "auroresBorealesLamagnetosphere"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesLemodeleDeBohr-Rutherford"] } }
    ) {
      ...FragmentImagePaysageMicro
    }
    imagesPortraitTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUnportraitDeFridtjofNansen"
            "auroresBorealesUnegravureDauroreDeFridtjofNansen"
            "auroresBorealesLemodeleDeBohr-Rutherford"
            "auroresBorealesLescouchesDeLatmosphereTerrestre"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitTresPetit
    }
    imagesGif: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesEjectiondeMasseCoronale"] } }
    ) {
      ...FragmentImageEtLegendeGif
    }
  }
`

export default function UnderstandingNorthernLights() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageComprendreAuroresBoreales")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit",
    "imagesPaysageMicro",
    "imagesPortraitTresPetit",
    "imagesGif"
  )

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Northern and southern lights are extremely complex natural phenoma :
          they still holds very well their secrets (NASA sent new satellites in
          March 2015 to better understand them)… However, we know a lot of
          things. Here are the main key points.
        </p>
        <p>
          <strong>
            Not being a professional physicist, this article is a compilation of
            all my readings and exchanges I could have had with professionals.
          </strong>
        </p>
      </Section>
      <Section titre="A little history…">
        <h3>Myths and legends</h3>
        <p>
          To explain the formation of northern lights, arctic civilizations have
          imagined <strong>many legends.</strong> The Sami people tell that this
          is the snow raised by the polar fox tail that goes up to the sky to
          form the auroras. For the Inuit of Greenland, northern lights are the
          souls of the dead who play with the skull of walruses…
        </p>
        <p>
          Asian peoples also have their beliefs. 2000 years before Jesus Christ,
          a Chinese empress had difficulties conceiving a child. Some time after
          seeing a northern light, she got pregnant. That is why we meet many
          asian couples in Lapland: making a child under northern lights would
          offer him a fantastic destiny.
        </p>
        <h3>Fridtjof Nansen</h3>
        <p>
          The polar explorers were the <strong>first</strong> to tell civilized
          countries the beauty of the auroras. One of the most famous, Fridtjof
          Nansen, tried to reach the North Pole with his boat “Fram” in 1895-96,
          using the drift of the icecap. Meanwhile, he wrote a book “Farthest
          North” containing stories and illustrations of northern lights.{" "}
        </p>
        <p>A museum was dedicated to him in Oslo, where the Fram is exposed.</p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnportraitDeFridtjofNansen"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnegravureDauroreDeFridtjofNansen"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUneautreGravureDauroreDeFridtjofNansen"
            )}
            langue={page.langue}
          />
        </div>
        <h3>Kristian Birkeland</h3>
        <p>
          Kristian Birkeland is a well-known norwegian physicist for being the
          first in 1895 to <strong>reproduce aurora</strong> in laboratory
          during the “<strong>Terella</strong>” experiments (“small Earth” in
          latin language). He discovered that the northern lights are the result
          of an interaction between solar wind particles and the Earth’s
          magnetic field. Kristian Birkeland’s many contributions to the science
          led him to be nominated 8 times for the Nobel prize: four times in
          chemistry and four times in physics.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesLExperienceTerella")}
          langue={page.langue}
        />
        <p>
          Norway paid him a tribute by creating a 200 crowns banknote (20 €) in
          his effigy. It is decorated with drawings of his experiences, his
          discoveries with northern lights.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnbilletDe200KronerRecto"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnbilletDe200KronerVerso"
            )}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="On the Sun…">
        <h3>The solar wind</h3>
        <p>
          Northern lights comes from the Sun. It <strong>ejects</strong> a
          continuous flow of plasma (an electrically charged gas) in all
          directions. The consistency of this plasma (density, speed) is
          directly linked to the solar activity: explosions, coronal holes…
        </p>
        <p>
          The influence zone of this wind is called the heliosphere and it
          extends well beyond the Pluto orbit. The end of this zone is called
          the heliopause. the Voyager&nbsp;I probe, launched in 1977, reached it
          in 2002 after a 25 years travel in space.
        </p>
        <h3>Solar spots</h3>
        <p>
          The spots are the seat of <strong>powerful magnetic phenomena</strong>{" "}
          on the surface of the Sun. These slow down the convection phenomena
          that produces under the surface of the Sun, which has the effect of
          cooling the gas zone that is around. As gas is less hot, it is less
          bright and therefore darker: these are the solar spots.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesTachesSolaires")}
          langue={page.langue}
        />
        <p>
          However, solar spots are not at the origin of the northern lights by
          themselves. These are just colder gases than the other surrounding
          gases. For a spot to be likely to form a northern light on Earth, it
          must take an eruption, then maybe a ejection of coronal mass.
        </p>
        <h3>Eruptions and coronal mass ejections</h3>
        <p>
          Eruptions then coronal mass ejections often have the solar spots for
          origin. These are often formed by pairs: a spot corresponds to a
          magnetic north pole, and the other one is the south magnetic pole. It
          then form powerful magnetic arcs, around which plasma can aggregate
          during an eruption.{" "}
          <strong>
            When the magnetic field does not ensure the consistency of the arc
          </strong>
          , it breaks and release all the plasma that he contained in space: it
          is the coronal mass ejection (CME). If the plasma is released towards
          the Earth, a northern light can happen a few days later.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesEjectiondeMasseCoronale")}
          src="/ressources/gif/auroresBorealesEjectiondeMasseCoronale.gif"
          largeur="320"
          langue={page.langue}
        />
        <h3>Coronal holes</h3>
        <p>
          Coronal holes are areas where the lines of the magnetic field of the
          Sun are open to space. Solar plasma is not retained and a continuous
          flow is ejected to space. Coronal holes are real open doors to the
          interior of the Sun and can also be at the origin of the northern
          lights if they are facing Earth.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "auroresBorealesUntrouCoronal")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLeslignesDeChampsMagnetiquesDunTrouCoronal"
            )}
            langue={page.langue}
            largeur={200}
          />
        </div>
        <h3>The solar cycle</h3>
        <p>
          Since the Sun is observed and studied, it was found that a peak of
          solar spots is forming <strong>every 11 years</strong>. But even at
          the lowest of this solar cycle, it is <strong>quite possible</strong>{" "}
          to observe beautiful northern lights on Earth. They comes from the
          coronal holes, which appear very regularly on the surface of the Sun.
        </p>
      </Section>
      <Section titre="On Earth…">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <h3>The Earth’s atmosphere</h3>
            <p>
              The atmosphere is <strong>the gaseous envelope</strong> which
              surrounds the Earth. It is composed of 78% diazote (N<sub>2</sub>)
              and 21% oxygen (O<sub>2</sub>). It has different layers
              (illustration on the right) and it is recognized that the 100
              kilometers altitude (the Karman limit) separates the atmosphere of
              space.
            </p>
            <h3>The ionosphere</h3>
            <p>
              The ionosphere is <strong>the last atmospheric layer</strong>. It
              extends from 50 to 1000 kilometers above the surface, overlapping
              the mesosphere, the thermosphere and the exosphere (see
              illustration). The ionosphere is a plasma : atomic oxygen ions and
              atomic nitrogen bathe in a “soup” of electrons:
            </p>
            <ul>
              <li>
                Atomic oxygen and atomic nitrogen come from a dissociation
                phenomenon of dioxygen and diazote, caused by the UV and X
                radiation emitted by the Sun.
              </li>
              <li>
                This radiation also causes ionization of these atoms (the “soup”
                of electrons).
              </li>
            </ul>
            <h3>The magnetosphere</h3>
            <p>
              The magnetosphere corresponds to the region located{" "}
              <strong>under a planet magnetic field influence</strong>. It is
              produced by the agitation of the planet metal core. The
              magnetosphere has no altitude limit: the magnetic field is
              constantly deformed by the solar wind that undergoes a planet.
            </p>
            <p>
              The magnetosphere is a true <strong>protective shield</strong>.
              For example, the Mars planet, whose metal kernel has become very
              little active over time, has a very weak magnetosphere. As a
              result, all its atmosphere has been blown by the solar wind and
              all its water was evaporated in the cosmos.
            </p>
          </div>
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLescouchesDeLatmosphereTerrestre"
            )}
            langue={page.langue}
            afficherLegende={false}
            largeur={128}
          />
        </div>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLeventSolaireSouffleSurLaTerreDeformantLaMagnetosphere"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "auroresBorealesLamagnetosphere")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="The formation of the northern lights">
        <h3>The light emission phenomenon</h3>
        <p>
          Solar plasma particles penetrate in the ionosphere{" "}
          <strong>by the poles</strong>. They are then guided by the magnetic
          field lines. By borrowing these field lines, plasma particles will
          excite on their passage the ionosphere ions, which will then emit
          light.
        </p>
        <h3>The auroral ovals</h3>
        <p>
          Plasma particles arrive on Earth by the poles. They move by following
          complex electrical circuits connecting the magnetosphere with
          ionosphere. Birkeland was the first to suspect the existence of these
          circuits, and the model he proposed was since completed.
        </p>
        <p>
          At the end, these electrical circuits form <strong>a circle</strong>{" "}
          around the magnetic poles. A bright oval is forming: it’s the auroral
          oval.
        </p>
        <p>
          The auroral oval can form both in <strong>south and north</strong>
          {""}: we say “northern lights” when the oval forms north (around the
          north magnetic pole), and “southern lights” when the oval forms south
          (around the south magnetic pole).
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesUnovaleAuroral")}
          langue={page.langue}
        />
        <h3>The deformation of the auroral oval on the night side</h3>
        <p>
          The <strong>brightest northern lights</strong> happen on the{" "}
          <strong>night</strong> side of the Earth(see photo above). Which is
          rather paradoxical because it’s the day side of the Earth that is most
          exposed to the Sun. The image below help us to better understand the
          phenomenon: the structure of the Earth’s magnetosphere.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesMagnetosphereStructure")}
          langue={page.langue}
        />
        <p>
          On the arrival of a solar storm on magnetosphere (it’s a “magnetic
          storm”), two scenarios happen:
        </p>
        <ul>
          <li>
            <strong>1. </strong> The particles of the storm rush through the
            cones. This scenario <strong>cannot lead</strong> to a bright
            aurora, because the base of the polar cones is very narrow and the
            magnetic field is very strong. Few of particles will enter the
            ionosphere by this way.
          </li>
          <p />
          <li>
            <strong>2.</strong> The following scenario explains the formation of
            bright auroras and its description is simplified.{" "}
            <u>Check my references for more details.</u>
            <p>
              The plasma particles are then repelled into the plasmagain (see
              diagram below) on the night side.
            </p>
            <p>
              The plasmagain is separated in 2 parts (upper and lower) by a
              neutral sheet. During a strong solar storm, they will fill. Under
              the pressure of the upper layers of the magnetosphere (lobes),{" "}
              {""}
              <strong> the 2 plasma sheets will also stretch </strong>. These 2
              combined effects will have the effect of compressing the neutral
              sheet. The lower and upper parts will then be join: a violent
              magnetic phenomenon occurs, {""}
              <strong> the “reconnection”. </strong>
            </p>
            <p>
              This reconnection{" "}
              <strong> will catapult plasma particles </strong> towards the
              ionosphere: it is the “sub magnetic storm”. The particles are
              accelerated and will penetrate in number in the ionosphere, which
              will produce bright auroras. This phenomenon is, however, still
              poorly understood by scientists.
            </p>
            <p>
              Finally, note that there may be a magnetic storm even when no
              magnetic storm has arrived on Earth. The magnetic storm{" "}
              <strong> just creates favorable conditions </strong> {""}
              for a sub magnetic storm to occur. So we can observe auroras even
              when there is no solar storm. However, they will be less bright
              and shorter.
            </p>
          </li>
        </ul>
        <h3>The position of the ovals</h3>
        <p>
          Auroral ovals form around magnetic poles and have a width varying from
          500 to 1000 kilometers approximately. However, their diameter will
          vary depending on the point where the magnetic reconnection happen on
          the night side (see above). The more it gets far, the more the lines
          of magnetic fields are pulled, which has effect to pull further south
          (or north for the southern auroras) the auroral ovals.
        </p>
        <p>Therefore:</p>
        <ul>
          <li>
            During a strong sub magnetic storm, an observer who is too north
            will see the aurora on the southern horizon.
          </li>
          <li>
            When the sub magnetic storm is not strong enough, an observer
            located too far south will see the aurora on the northern horizon.
          </li>
        </ul>
      </Section>
      <Section titre="In summary…">
        <p>
          The following video is interesting because it shows the sequence
          phenomena that is at the origin of the northern lights on Earth. It is
          shown in the Polaria Museum of Tromsø, Norway.
        </p>
        <div className="conteneur-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/N5utQxtma2U"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Section>
      <Section titre="The colors of the northern lights">
        <h3>The Bohr-Rutherford model</h3>
        <p>
          The northern lights result from the <strong> luminescence </strong> of
          the upper atmosphere due to the {""}
          <strong>de-excitation of molecules in the atmosphere</strong>. For a
          better understanding of this phenomenon, we must first understand the
          structure of the atom.
        </p>
        <p>
          An atom is made up of particles: protons and neutrons form its
          nucleus, while electrons revolve around it. {""}
          <i>
            Attention, the model described here is now outdated by the
            principles of quantum mechanics, and is only used for educational.
          </i>
        </p>
        <p>
          Rutherford was the first to assign a{" "}
          <strong>
            planetary structure {""}
            to the atom{" "}
          </strong>
          : the electrons are separated by void and revolve around the nucleus,
          like the planets around the Solar system.
        </p>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesLemodeleDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
        <p>
          Niels Bohr completed Rutherford’s model by bringing the following
          constraints:
        </p>
        <ul>
          <li>
            An <strong>electron</strong> can be found {""}
            <strong>only in a precise orbit</strong> around the atom: the
            electronic layers.
          </li>
          <li>
            <strong>Electrons</strong> cannot <strong>change layer</strong>{" "}
            (unless they are excited, see below).
          </li>
          <li>
            Each of these layers can receive a <strong>precise number</strong>{" "}
            of electrons.
          </li>
          <li>
            A layer can only accommodate electrons if the {""}
            <strong>previous one has been fulfilled</strong>. There are 7
            different electronic layers: the K layer is closest to the nucleus
            (level 1, the “fundamental” orbit), and the Q layer is the most
            distant (level 7). Each has an energy level denoted “n”. The lowest
            energy is the one of the layer that is closest to the nucleus (layer
            K, n = 1), the strongest one is the one that is the farthest from
            the nucleus (Q layer, n = 7).
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesLemodelePlanetaireDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
        <h3>The Luminescence</h3>
        <p>
          We have just seen that according to Bohr, the electrons can be located
          only on <strong>a well defined layer</strong> around the nucleus of
          the atom and that they could not change it.
        </p>
        <p>
          <strong>Unless they are excited</strong>: shock with another particle
          (photon, free electron, etc.), impact with an atom… If supplied energy
          is sufficient and not too strong, Bohr specifies that the electron can
          change its orbit, and then return to its original position. But to
          return to its original position (a lower energy level layer), the
          electron must {""}
          <strong>release its excess of energy</strong> in the form of a {""}
          <strong>photon</strong>.
        </p>
        <p>
          The return to the original orbit of the electron can be accomplished
          in one or many stages:
        </p>
        <ul>
          <li>Gradually return to the original orbit.</li>
          <li>Return to original orbit in one jump.</li>
          <li>Or return to the original orbit by skipping a few orbits.</li>
        </ul>
        <p>
          <strong>The wavelength</strong> of the emitted photon will depend{" "}
          <strong>of the return jump made</strong> and of the excited atom. If
          the wavelength of the emitted photon is part of the visible domain
          (the light), then we will see a <strong>color</strong>.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesLaluminescence")}
          langue={page.langue}
        />
        <h3>Collisional deactivation</h3>
        <p>
          An excited atom (therefore having received a surplus of energy) has
          seen one or many of its electrons change orbit. These electrons are
          themselves in unstable states. But they can re-stabilize :
        </p>
        <ul>
          <li>
            They can descend from an orbit: a <strong>photon</strong> is emitted
            (this is the {""}
            <strong>luminescence</strong>).
          </li>
          <li>
            <strong>Or come into contact</strong> with another element (atom /
            molecule / ion…): in this case, the excess energy is transmitted to
            element encountered, and no photon is emitted: {""}
            <strong>this is collisional deactivation</strong>.
          </li>
        </ul>
        <h3>Finally…</h3>
        <p>
          We now have all the elements to understand the color of the aurora.
          They are often green in their lower parts and red in their upper
          parts. This is explained by the variation of atmospheric density and
          atmospheric composition, which is depending on the altitude.
        </p>
        <p>
          <strong>Red and blue</strong>
        </p>
        <p>
          In the upper layers of the ionosphere, the atmospheric density becomes
          very weak. So the oxygen electrons have the time to go down to 2
          orbits (2 minutes will be necessary) to stabilize completely (return
          to their fundamental orbits): they emit a red photon. Blue-violet may
          also appear on the peak of the aurora: in this case, it comes from the
          excitement of hydrogen, ionized nitrogen and ionized helium. These
          gases are lighter than oxygen and are only found in the highest layers
          of the atmosphere: it requires a strong solar storm for them to be
          excited.
        </p>
        <p>
          <strong>Green</strong>
        </p>
        <p>
          In the middle layers of the ionosphere, atomic oxygen is predominant
          (dioxygen is heavier and is found much lower in the atmosphere). The
          electrons of the excited atom have time to descend from their orbit
          only once before being deactivated by another atom: they emit green.
        </p>
        <p>
          <strong>Other colors</strong>
        </p>
        <p>
          The composition of the low to medium altitude layers of the ionosphere
          is very varied: atomic oxygen, atomic nitrogen, ionized nitrogen…
          Atomic nitrogen emits red, oxygen emits green, and ionized nitrogen
          emits blue. If the solar wind particles have sufficient energy to
          reach these lower layers before being stopped, a large palette of
          colors can be observed: pink, yellow, white…
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesKp888Sur9")}
          langue={page.langue}
        />
        <Image
          image={obtenirImage(images, "auroreTaiga")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Sources and references">
        <ul>
          <li>Wikipedia (french and UK)</li>
          <li>
            <Lien urlExterne="http://asahi-classroom.gi.alaska.edu/">
              Asashiwa classroom
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://arena.obspm.fr/~luthier/mottez/intro_physique_spatiale/sommaire.html">
              Introduction to space physics, by the Paris Observatory
            </Lien>{" "}
            (in french)
          </li>
          <li>
            <Lien urlExterne="https://tel.archives-ouvertes.fr/tel-00803178/document">
              Aurora and particle escape above the caps terrestrial polar
            </Lien>
            , by Alexandra Teste (in french)
          </li>
          <li>
            <Lien urlExterne="http://astronomia.fr/">Astronomia</Lien> (in
            french)
          </li>
          <li>
            <Lien urlExterne="http://www.astrosurf.com/luxorion/aurore.htm">
              Luxorion
            </Lien>{" "}
            (in french)
          </li>
          <li>
            <Lien urlExterne="http://www.phy6.org/">Phy6</Lien> (in french)
          </li>
        </ul>
      </Section>
    </LayoutTutorielEN>
  )
}
